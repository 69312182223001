<div class="component-wrapper">
    <div class="title-arrow">
        <p class="title">{{textConstants.FILTER_KPI_DATA}}</p>

        <svg class="arrow" [ngClass]="{'rotate': isFilterContainerOpen}" (click)="onClick()" width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14.5" cy="14.5" r="14.5" transform="rotate(-180 14.5 14.5)" fill="#503291"/>
            <path d="M20.2119 13.1819L14.4998 18.4546L8.78767 13.1819" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>                 
    </div>

    <div class="filters-wrapper" [@toggle]="state">
        <div class="calendar-select-wrapper">
            <!-- <fd-calendar 
                (rangeSelect)="setDateRange($event)">
           </fd-calendar> -->

            <div class="select-wrapper">
                <div>
                    <p class="select-label">{{textConstants.SELECT}}</p>
                </div>
                
                <div class="select">
                    <fd-select  
                        *ngFor="let select of SELECT_CONFIGURATION;" 
                        (valueSelect)="callAddChipMethod($event)" 
                        [type]="select.type" 
                        [placeholder]="select.placeholder"
                        [options]="select.options">
                    </fd-select>
                </div>             
            </div>           
        </div>
    
        <div *ngIf="isFilterContainerOpen" class="chip-collection-wrapper">
            <fd-chip-collection  
                *ngFor="let select of SELECT_CONFIGURATION" 
                [type]="select.type" 
                [backgroundColor]="select.chipColor">
            </fd-chip-collection> 
        </div>   
        
        <div class="filter-button-wrapper">
            <button 
                mat-flat-button
                (click)="emitFilterRequestModel()"
                >{{textConstants.FILTER_BUTTON_TEXT}}
            </button>
        </div>
    </div>  
</div>
