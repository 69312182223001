import { Component, Input } from '@angular/core';

@Component({
  selector: 'fd-is-card',
  templateUrl: './fd-is-card.component.html',
  styleUrls: ['./fd-is-card.component.scss']
})
export class FdIsCardComponent {
  @Input() color!: string;
  @Input() boxShadow!: string;
  @Input() text!: string;
  @Input() value!: string | number | null;
  @Input() width!: string;
}
