import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FdMapComponent } from './components/fd-map/fd-map.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { RegionPageComponent } from './pages/region-page/region-page.component';

import { FdIsCardComponent } from './components/fd-is-card/fd-is-card.component';
import { FdFilterComponent } from './components/fd-filter/fd-filter.component';
import { FdSelectComponent } from './components/fd-select/fd-select.component';
import { FdChipComponent } from './components/fd-chip/fd-chip.component';
import { FdFlipIconComponent } from './components/fd-flip-icon/fd-flip-icon.component';
import { FdSearchFieldComponent } from './components/fd-search-field/fd-search-field.component';
import { FdChipCollectionComponent } from './components/fd-chip-collection/fd-chip-collection.component';
import { FdCalendarComponent } from './components/fd-calendar/fd-calendar.component';
import { FdCyclePotentialComponent } from './components/fd-cycle-potential/fd-cycle-potential.component';

import { SharedModule } from './submodules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FdImplementationStatusComponent } from './components/fd-implementation-status/fd-implementation-status.component';
import { FdBarChartComponent } from './components/fd-bar-chart/fd-bar-chart.component';
import { FdMarketsCpComponent } from './components/fd-markets-cp/fd-markets-cp.component';

import { CookieService } from 'ngx-cookie-service';

import fr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { RouterStateSnapshot } from '@angular/router';

registerLocaleData(fr);

@NgModule({
  declarations: [
    AppComponent,
    FdMapComponent,
    HomePageComponent,
    RegionPageComponent,
    FdIsCardComponent,
    FdFilterComponent,
    FdSelectComponent,
    FdChipComponent,
    FdCalendarComponent,
    FdCyclePotentialComponent,
    FdFlipIconComponent,
    FdSearchFieldComponent,
    FdChipCollectionComponent,
    FdImplementationStatusComponent,
    FdBarChartComponent,
    FdMarketsCpComponent,
    ErrorPageComponent,
    EmptyStateComponent,
    AccessDeniedComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
