import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fd-map',
  templateUrl: './fd-map.component.html',
  styleUrls: ['./fd-map.component.scss']
})
export class FdMapComponent {

  constructor(private router: Router) {
  }

  goToRegionPage(id: number): void{
    this.router.navigate(['region', id]);
  }
}
