<div class="page-wrapper">
    <div class="inner-wrapper">
        <img src="../../../assets/images/error_page_illustration.png">
        <p class="merck-font-title">{{textConstants.ERROR_404}}</p>
        <p>{{textConstants.ERROR_MESSAGE}}</p>
        <button 
            mat-flat-button
            (click)="goBack()">
            {{textConstants.GO_BACK}}
        </button>
    </div>
    
</div>
