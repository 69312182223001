<mat-drawer-container class="sidenav-wrapper">
    <mat-drawer mode="side" opened class="sidenav">
        <div class="navigation-wrapper">
            <button 
                mat-icon-button 
                [ngClass]="{'active': routeItem.active, 'hovered': routeItem.hovered}" 
                *ngFor="let routeItem of SIDENAV_CONFIGURATION" 
                [routerLink]="[routeItem.route]"
                (mouseenter)="applyClass(routeItem)"
                (mouseleave)="removeClass(routeItem)">
                    <img [src]="(routeItem.active || routeItem.hovered) ? routeItem.activeIconUrl : routeItem.iconUrl" alt="Nav Icon">
            </button>
        </div>
    </mat-drawer>
    <mat-drawer-content class="page">
        <div class="welcome-wrapper">
            <p class="merck-font" (click)="goToHomePage()">{{textConstants.MERCK}}</p>
            <p class="text">{{textConstants.WELCOME + name}}</p>
        </div>
        <fd-admin-panel-breadcrumb></fd-admin-panel-breadcrumb>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>