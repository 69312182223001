import { Component, OnInit } from '@angular/core';
import * as TextConstants from './constants/text.constants';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  textConstants = TextConstants;
  name = '';
  title = 'FertilityDashboardApp';
  
  showGreeting: boolean = true;
  isInAdminPanel: boolean = false;

  constructor(private router: Router,
    private authService: AuthService) {}
  
  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isInAdminPanel = this.router.url.includes('admin');
        this.showGreeting = this.router.url.includes('error');
        this.showGreeting = this.router.url.includes('access-denied');
      });

      this.authService.setUserData();
      this.name = this.authService.userName;
  }

  goToHomePage(){
    this.router.navigate(['home']);
  }
}
