import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { addRemoveAnimation } from 'src/app/animations/custom.animations';
import { FdChipComponent } from '../fd-chip/fd-chip.component';
import { FdChipModel } from 'src/app/models/fd-chip.model';
import { AddChipModel } from 'src/app/models/add-chip.model';

@Component({
  selector: 'fd-chip-collection',
  templateUrl: './fd-chip-collection.component.html',
  styleUrls: ['./fd-chip-collection.component.scss'],
  animations: [ addRemoveAnimation ]
})
export class FdChipCollectionComponent {
  @ViewChildren(FdChipComponent) chipComponents!: QueryList<FdChipComponent>;
  @Input() backgroundColor!: string;
  @Input() type!: string;
  chips: FdChipModel[] = [];

  addChip(data: AddChipModel): void{
    
    if(this.chipComponents.some(x => x.value === 0 || x.content === 'All initiatives')){
      //In case the user tries to add specific data option when all options choice has been selected.
      //Trigger the animation on the all option choice chip.
      let chip = this.chipComponents.find(x => x.value === 0 || x.content === 'All initiatives');
      chip!.isSelected = true;
      setTimeout(() => chip!.isSelected = false, 1000);
      
    }else if((data.id === 0 || data.content === 'All initiatives') && this.chipComponents.length > 0)
    {
      //In case the user selects all options choice while other options have been selected beforehand.
      //Remove all specific options selected and add all options choice.
      this.chipComponents.forEach(x => {
        this.removeChip(x.value);
      });

      this.chips.push({
        id: data.id,
        content: data.content
      });
    }else if(this.chipComponents.some(x => x.value === data.id)){
      //In case the user tries to add the same option.
      //Shake the option.
      let chip = this.chipComponents.find(x => x.value === data.id);
      chip!.isSelected = true;
      setTimeout(() => chip!.isSelected = false, 1000);
    }
    else{
      if(data.collectionType?.toLowerCase() === 'functions'){
        if(this.chips.length > 0){
          this.chips.pop();
        }
      }

      this.chips.push({
        id: data.id,
        content: data.content
      });
    }
  };

  removeChip(id: number | string){
    let index = this.chips.findIndex(x => x.id === id);
    this.chips.splice(index, 1);
  }
}
