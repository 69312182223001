import { Component, Input } from '@angular/core';
import * as TextConstants from '../../constants/text.constants';

@Component({
  selector: 'fd-cycle-potential',
  templateUrl: './fd-cycle-potential.component.html',
  styleUrls: ['./fd-cycle-potential.component.scss']
})
export class FdCyclePotentialComponent {
  @Input() heading!: string;
  @Input() subheading!: string;
  @Input() currentCycles!: number;
  @Input() potentialCycles!: number;

  textConstants = TextConstants;

}
