import { SelectModel } from "src/app/models/select.model";

export const SELECT_CONFIG: SelectModel[] = [
    {
        type: 'Hurdles',
        placeholder: 'Select hurdle/s',
        chipColor: 'rgba(150, 215, 210, 0.70)',
        options: [
            {
                id: 0,
                name: 'All hurdles'
            },
            {
                id: 1,
                name: 'Awareness'
            },
            {
                id: 2,
                name: 'Referral'
            },
            {
                id: 3,
                name: 'Financial'
            },
            {
                id: 4,
                name: 'Patient Centricity'
            }
        ]
    },
    {
        type: 'Initiatives',
        placeholder: 'Select initiative/s',
        chipColor: '#E1C3CD',
        options: []
    },
    {
        type: 'Statuses',
        placeholder: 'Select status/es',
        chipColor: '#82BCEA',
        options: [
            {
                id: 0,
                name: 'All statuses'
            },
            {
                id: 1,
                name: 'Planned'
            },
            {
                id: 2,
                name: 'Launched'
            },
            {
                id: 3,
                name: 'Ongoing'
            },
            {
                id: 4,
                name: 'Cancelled'
            },
            {
                id: 5,
                name: 'Completed'
            }
        ]
    },
    {
        type: 'Functions',
        placeholder: 'Select function',
        chipColor: '#BFADE7',
        options: [
            {
                id: 0,
                name: 'Cross-functional'
            },
            {
                id: 1,
                name: 'Medical'
            },
            {
                id: 2,
                name: 'Commercial'
            }
        ]
    }
];