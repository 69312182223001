import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fd-chip',
  templateUrl: './fd-chip.component.html',
  styleUrls: ['./fd-chip.component.scss']
})
export class FdChipComponent {
  @Input() content!: string;
  @Input() backgroundColor!: string;
  @Input() value!: number | string;
  @Output() removeChip = new EventEmitter<any>();
  isSelected = false;

  emitRemoveEvent(value: number | string): void{
    this.removeChip.emit(value);
  }
}
