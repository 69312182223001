import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private adminBreadcrumbsSubject = new BehaviorSubject<Array<{ label: string, url: string }>>([]);
  adminBreadcrumbs$: Observable<Array<{ label: string, url: string }>> = this.adminBreadcrumbsSubject.asObservable();

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateAdminBreadcrumbs(this.router.routerState.root.firstChild!);
      }
    });
  }

  private updateAdminBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Array<{ label: string, url: string }> = []): void {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      this.adminBreadcrumbsSubject.next(breadcrumbs);
      return;
    }

    const breadrumbValues = breadcrumbs.map(x => x.label);

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const data = child.snapshot.data;
      const resolve = child.snapshot.routeConfig?.resolve;

      let label: string = '';

      if (resolve && resolve['breadcrumb']) {
        label = resolve['breadcrumb'](child.snapshot);
      } else if (data && data['breadcrumb']) {
        label = data['breadcrumb'];
      }

      if (!breadrumbValues.includes(label)) {
        breadcrumbs.push({ label: label, url: url });
      }
      this.updateAdminBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
