import { Component } from '@angular/core'; 
import * as TextConstants from '../../constants/text.constants';
import { TOP_COUNTRIES_COLORS_CONFIGURATION } from './configurations/top-countries-colors';
import { TABLE_DISPLAYED_COLUMNS_CONFIGURATION } from './configurations/table-displayed-columns-configuration';
import { TABLE_HEADER_ROWS_CONFIGURATION } from './configurations/table-header-rows-configuration';
import { HomeService } from 'src/app/services/home.service';
import { GetGlobalCountriesDataResponseModel } from 'src/app/models/get-global-countries-data-response.model';
import { FilterRequestModel } from 'src/app/models/filter-request.model';
import { first } from 'rxjs';
import { TopHurdleViewModel } from 'src/app/models/top-hurdle-view.model';
import { HURDLES_CONFIGURATION } from './configurations/hurdles-configuration';
import { RegionDataModel } from 'src/app/models/region-data.model';
import { REGION_MAP_ICON_CONFIGURATION } from './configurations/region-configuration';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent{
  countriesColors = TOP_COUNTRIES_COLORS_CONFIGURATION;
  regionMapIconsCongig = REGION_MAP_ICON_CONFIGURATION;
  hurdlesConfig = HURDLES_CONFIGURATION;
  displayedColumns = TABLE_DISPLAYED_COLUMNS_CONFIGURATION;
  headerRows = TABLE_HEADER_ROWS_CONFIGURATION;
  textConstants = TextConstants;
  showSpinner = false;
  dataSource: RegionDataModel[] = [];
  firstTopHurdle: TopHurdleViewModel | null = null;
  secondTopHurdle: TopHurdleViewModel | null = null;

  filterRequest: FilterRequestModel = {
    startDate: null,
    endDate: null,
    countries: [],
    functionOptions: [],
    hurdleOptions: [],
    initiativeOptions: [],
    statusOptions: []
  };
  countriesData: GetGlobalCountriesDataResponseModel = {
    implementationStatus: {
      cyclePotential: 0,
      initiativesCancelled: 0,
      initiativesCompleted: 0,
      initiativesLaunched: 0,
      initiativesOngoing: 0,
      initiativesPlanned: 0,
      marketHurdles: 0
    },
    topCountries: [],   
    topHurdles: [],
    regionsData: [],
    cyclePotential: {
      currentCycles: 0,
      potentialCycles: 0
    },
    overallInfertilePopulationCount: 0
  };

  constructor(private homeService: HomeService) {
   this.getFilteredData(this.filterRequest);
  }

  getFilteredData(filterRequest: FilterRequestModel){
    this.showSpinner = true;

    this.homeService.getGlobalCountriesData(filterRequest)
    .pipe(first())
    .subscribe(response => {
      this.countriesData = response;

      this.countriesData.regionsData.forEach(region => {
        region.regionName = region.regionName.toUpperCase();

        const iconUrl = this.regionMapIconsCongig.find(x => x.regionName.toLowerCase() === region.regionName.toLowerCase())?.iconUrl ?? null;
        region.iconUrl = iconUrl;
      });
      this.dataSource = this.countriesData.regionsData;

      if(this.countriesData.topHurdles.length >= 1){
        this.firstTopHurdle = this.hurdlesConfig.find(x => x.name === this.countriesData.topHurdles[0])!;
      }
      if(this.countriesData.topHurdles.length >= 2){
        this.secondTopHurdle = this.hurdlesConfig.find(x => x.name === this.countriesData.topHurdles[1])!;
      }

      this.showSpinner = false;
    });
  }
}
