<div 
    [style.background-color]="backgroundColor" 
    class="component-wrapper"
    [ngClass]="{'shakeit': isSelected}">
    <p>{{content}}</p>

    <svg (click)="emitRemoveEvent(value)" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L11 11" stroke="#503291" stroke-linecap="round"/>
        <path d="M1 11L11 1" stroke="#503291" stroke-linecap="round"/>
    </svg>
        
</div>
