import { Component } from '@angular/core';
import * as TextConstants from '../../constants/text.constants';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent {
  textConstants = TextConstants;

}
