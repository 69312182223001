import { Component, Input } from '@angular/core';
import * as TextConstants from '../../constants/text.constants';
import { ImplementationStatusModel } from 'src/app/models/implementation-status.model';

@Component({
  selector: 'fd-implementation-status',
  templateUrl: './fd-implementation-status.component.html',
  styleUrls: ['./fd-implementation-status.component.scss']
})
export class FdImplementationStatusComponent {
  @Input() subheading!: string;
  @Input() data!: ImplementationStatusModel;
  @Input() femalePopulationWithInfertility!: number;

  textConstants = TextConstants;
}
