import { environment as env } from "src/environments/environment";

export const COUNTRY_DATA_CONTROLLER_ROUTE = env.beBaseUrl + '/api/country-data/';
export const COUNTRY_CONTROLLER_ROUTE = env.beBaseUrl + '/api/country/';
export const REGION_CONTROLLER_ROUTE = env.beBaseUrl + '/api/region/';
export const INITIATIVES_CONTROLLER_ROUTE = env.beBaseUrl + '/api/initiatives/';
export const HOME_CONTROLLER_ROUTE = env.beBaseUrl + '/api/home/';
export const REPORT_CONTROLLER_ROUTE = env.beBaseUrl + '/api/report/';
export const AUTH_CONTROLLER_ROUTE = env.beBaseUrl + '/api/auth/';
export const USER_CONTROLLER_ROUTE = env.beBaseUrl + '/api/user/';

export const UPSERT_COUNTRY_DATA_ROUTE = `${COUNTRY_DATA_CONTROLLER_ROUTE}` + 'upsert-country-data';
export const GET_COUNTRY_DATA_ROUTE = `${COUNTRY_DATA_CONTROLLER_ROUTE}` + 'get-country-data';

export const GET_COUNTRY_OPTIONS_ROUTE = `${COUNTRY_CONTROLLER_ROUTE}` + 'get-country-options';

export const GET_REGION_OPTIONS_ROUTE = `${REGION_CONTROLLER_ROUTE}` + 'get-region-options';
export const GET_REGION_DATA_ROUTE = `${REGION_CONTROLLER_ROUTE}` + 'get-region-data';

export const GET_INITIATIVES_DATA_ROUTE = `${INITIATIVES_CONTROLLER_ROUTE}` + 'get-initiatives-data';

export const GET_GLOBAL_COUNTRIES_DATA_ROUTE = `${HOME_CONTROLLER_ROUTE}` + 'get-global-countries-data';
export const GET_INITIATIVE_OPTIONS_ROUTE = `${HOME_CONTROLLER_ROUTE}` + 'get-initiative-options';

export const GENERATE_PDF_REPORT_ROUTE = `${REPORT_CONTROLLER_ROUTE}` + 'generate-pdf-report';
export const AUTHORIZE_ROUTE = `${REPORT_CONTROLLER_ROUTE}` + 'authorize';

export const GET_ALL_USERS_ROUTE = `${USER_CONTROLLER_ROUTE}` + 'get-all';
export const GET_ONE_USER_ROUTE = `${USER_CONTROLLER_ROUTE}` + 'get-one';
export const EDIT_USER_ROLE_ROUTE = `${USER_CONTROLLER_ROUTE}` + 'edit-user-role';
export const ADD_USER_ROUTE = `${USER_CONTROLLER_ROUTE}` + 'add-user';
export const DELETE_USER_ROUTE = `${USER_CONTROLLER_ROUTE}` + 'delete-user';