import { TopHurdleViewModel } from "src/app/models/top-hurdle-view.model";

export const HURDLES_CONFIGURATION: TopHurdleViewModel[] = [
    {
        name: 'Financial',
        iconUrl: '../../../assets/images/financial_hurdle_icon.svg',
        description: 'Many patients with fertility issues lack the financial resources for adequate treatment involving assisted reproductive technologies.',
        backgroundColor: '#EB3C96'
    },
    {
        name: 'Awareness',
        iconUrl: '../../../assets/images/awareness_hurdle_icon.svg',
        description: 'There is a lack of awareness about age-related fertility decline due to reduced oocyte quantity and quality, as well as limited knowledge of treatment options.',
        backgroundColor: '#503291'
    },
    {
        name: 'Referral',
        iconUrl: '../../../assets/images/referral_hurdle_icon.svg',
        description: 'Women and couples seeking fertility treatment often experience referral delays from OBGYNs to reproductive specialists.',
        backgroundColor: '#2DBECD'
    },
    {
        name: 'Patient Centricity',
        iconUrl: '../../../assets/images/patient_centricity_hurdle_icon.svg',
        description: 'Clinics struggle to handle increasing number of patient, affecting the quality of services and patient journeys for optimal clinical outcomes.',
        backgroundColor: '#3067AA'
    }
];