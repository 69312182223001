import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as RouteConstants from '../constants/route.constants';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  routeConstants = RouteConstants;
  userName = '';
  private jwtHelperService = new JwtHelperService();

  constructor(private httpClient: HttpClient, 
    private cookieService: CookieService, 
    private router: Router) { }
    
  getToken(): string {
    return this.cookieService.get('_fdat');
  }

  authorize(): Observable<any> {
    var queryParams = new HttpParams();
    queryParams = queryParams.append('returnUrl', this.router.url);

    return this.httpClient.get<any>(this.routeConstants.AUTHORIZE_ROUTE, {params: queryParams});
  }

  get isLoggedIn(): boolean{
    return this.cookieService.check('_fdat');
  }

  isInRole(roles: string[]): boolean{
    const token = this.cookieService.get('_fdat');
    const decodedToken = this.jwtHelperService.decodeToken(token);
    roles = roles.map(x => x.toLowerCase());

    return (
      decodedToken.roles?.some((role:string) => roles.includes(role.toLowerCase())) 
      || roles.includes(decodedToken.role?.toLowerCase())
    ) 
    ?? false;
  }

  setUserData(){
    const token = this.cookieService.get('_fdat');
    const decodedToken = this.jwtHelperService.decodeToken(token);
    
    this.userName = `${decodedToken.given_name} ${decodedToken.family_name}`;
  }
}
