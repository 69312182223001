import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

import { environment as env } from 'src/environments/environment';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if(!authService.isLoggedIn){
    window.location.href = `${env.beBaseUrl}/api/auth/authorize?returnUrl=${state.url}`;
    return false;
  }

  if(!authService.isInRole(['User', 'Admin', 'SuperAdmin'])){
    return router.createUrlTree(['/access-denied']);
  }

  return authService.isLoggedIn;
};
