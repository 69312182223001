import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { dropdownAnimation } from 'src/app/animations/custom.animations';
import { AddChipModel } from 'src/app/models/add-chip.model';
import { SelectOptionModel } from 'src/app/models/select-option.model';

@Component({
  selector: 'fd-select',
  templateUrl: './fd-select.component.html',
  styleUrls: ['./fd-select.component.scss'],
  animations: [
    dropdownAnimation
  ]
})

export class FdSelectComponent {
  @Input() type: string = '';
  @Input() placeholder: string = '';
  @Input() options: SelectOptionModel[] = [];

  @Output() valueSelect = new EventEmitter<AddChipModel>();

  isOptionContainerOpen = false;
  state: string = 'not-visible';

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOptionContainerOpen = false;
      this.state = 'not-visible';
    }
  }
  
  onClick(): void{
    this.isOptionContainerOpen = !this.isOptionContainerOpen;
    this.state = this.state === 'not-visible' ? 'visible' : 'not-visible';
  }

  emitChipContent(option: SelectOptionModel): void{
    let data: AddChipModel = {
      id: option.id,
      content: option.name,
      collectionType: this.type
    };

    this.valueSelect.emit(data);
  }
}
