import { Injectable } from '@angular/core';
import * as RouteConstants from '../constants/route.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RegionSelectOptionModel } from '../models/region-select-option.model';
import { RegionViewDataModel } from '../models/region-view-data.model';
import { FilterRequestModel } from '../models/filter-request.model';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  private routeConstants = RouteConstants;

  constructor(private httpClient: HttpClient) { }

  getRegionOptions(): Observable<RegionSelectOptionModel[]>{ 
    return this.httpClient.get<RegionSelectOptionModel[]>(this.routeConstants.GET_REGION_OPTIONS_ROUTE);
  }

  getRegionData(regionId: number, filterRequest: FilterRequestModel): Observable<RegionViewDataModel>{
    let queryParams = new HttpParams();
    queryParams = queryParams.append('regionId', regionId);
    
    return this.httpClient.post<RegionViewDataModel>(this.routeConstants.GET_REGION_DATA_ROUTE, filterRequest, {params: queryParams});
  }
}
