import { Component, OnInit } from '@angular/core';
import { ROUTE_ICONS_CONFIG, RouteItemIcon } from './configuration/route-icons-configuration';
import { Router } from '@angular/router';
import * as TextConstants from 'src/app/constants/text.constants';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  SIDENAV_CONFIGURATION = ROUTE_ICONS_CONFIG;
  textConstants = TextConstants;
  name = '';

  constructor(private router: Router, private authService: AuthService) {}
  
  ngOnInit(): void {
      this.router.events.subscribe(() => {
        const initialRouteIcon = this.SIDENAV_CONFIGURATION.find(x => this.router.url.includes(x.route)) 
        ?? this.SIDENAV_CONFIGURATION.find(x => x.route === "home")!;

        this.markAsActive(initialRouteIcon);
      });

      this.authService.setUserData();
      this.name = this.authService.userName;
  }

  applyClass(routeItem: RouteItemIcon): void {
    routeItem.hovered = true;
  }

  removeClass(routeItem: RouteItemIcon): void {
    routeItem.hovered = false;
  }

  goToHomePage(){
    this.router.navigate(['home']);
  }

  private markAsActive(routeItem: RouteItemIcon) {
    this.SIDENAV_CONFIGURATION.forEach(x => x.active = false);

    routeItem.active = true;
  }
}
