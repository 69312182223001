import { Component } from '@angular/core';
import { Location } from '@angular/common';
import * as TextConstants from '../../constants/text.constants';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  textConstants = TextConstants;

  constructor(private location: Location) {}

  goBack(){
     this.location.back();
  }
}
