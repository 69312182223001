export const TOP_COUNTRIES_COLORS_CONFIGURATION = new Map<number, string>([
    [0, '#EB3C96'],
    [1, '#503291'],
    [2, '#0F69AF'],
    [3, '#2DBECD'],
    [4, '#96D7D2'],
    [5, '#A5CD50'],
    [6, '#149B5F'],
    [7, '#F26921'],
    [8, '#C74806'],
    [9, '#FFC832']
]);
    

