import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as TextConstants from 'src/app/constants/text.constants';
import { dropdownAnimation } from 'src/app/animations/custom.animations';
import { CountrySelectOptionModel } from 'src/app/models/country-select-option.model';
import { AddChipModel } from 'src/app/models/add-chip.model';

@Component({
  selector: 'fd-search-field',
  templateUrl: './fd-search-field.component.html',
  styleUrls: ['./fd-search-field.component.scss'],
  animations: [ dropdownAnimation ]
})
export class FdSearchFieldComponent implements OnChanges{
  @Input() options: CountrySelectOptionModel[] = [];
  @Output() valueSelect: EventEmitter<AddChipModel> = new EventEmitter<AddChipModel>();

  suggestions: CountrySelectOptionModel[] = [];
  textConstants = TextConstants;
  isOptionContainerOpen = false;
  state: string = 'not-visible';

  formControl: UntypedFormControl = new UntypedFormControl('');

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges){
    if(changes['options']){
      this.suggestions = this.options;
    }
  }
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOptionContainerOpen = false;
      this.state = 'not-visible';
    }
  }

  onSearch(): void {
    this.valueSelect.emit(this.formControl.value);
  }

  onClick(): void{
    this.isOptionContainerOpen = !this.isOptionContainerOpen;
    this.state = this.state === 'not-visible' ? 'visible' : 'not-visible';
  }

  onKeyUp(){
    this.isOptionContainerOpen = true;
    this.state = 'visible';
    this.suggestions = this.options.filter(x => x.name.toLowerCase().includes(this.formControl.value.toLowerCase()));
  }

  emitChipContent(id: number, content: string): void{
    let data: AddChipModel = {
      id,
      content,
      collectionType: null
    };
    this.valueSelect.emit(data);
  }
}
