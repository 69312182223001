<div class="component-wrapper">
    <div class="wrapper" [ngClass]="{'shadow radius': isOptionContainerOpen}">
        <div class="inner-wrapper">
            <p>{{placeholder}}</p>
            <svg class="arrow" (click)="onClick()" [ngClass]="{'rotate': isOptionContainerOpen}" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7L13 1" stroke="#503291" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> 
        </div>
           
        <div [@toggle]="state" class="options-wrapper shadow">           
            <div class="option-wrapper" *ngFor="let option of options" (click)="emitChipContent(option)">  
                <mat-divider></mat-divider>         
                <p class="option"> {{option.name}} </p> 
            </div>    
        </div> 
    </div>
</div>