import * as textConstants from '../../../constants/text.constants';

export const REGION_MAP_ICON_CONFIGURATION: any[] = [
    {
        regionName: textConstants.EUROPE,
        iconUrl: '../../../assets/images/global_table_europe_vector.svg'
    },
    {
        regionName: textConstants.MEAR,
        iconUrl: '../../../assets/images/global_table_mear_vector.svg'
    },
    {
        regionName: textConstants.APAC,
        iconUrl: '../../../assets/images/global_table_apac_vector.svg'
    },
    {
        regionName: textConstants.LATAM,
        iconUrl: '../../../assets/images/global_table_latam_vector.svg'
    },
    {
        regionName: textConstants.NA,
        iconUrl: '../../../assets/images/global_table_na_vector.svg'
    },
    {
        regionName: textConstants.CN,
        iconUrl: '../../../assets/images/global_table_cn_vector.svg'
    },
];

