<div class="component-wrapper">
    <div 
        class="header"
        [style.background]="headerBackgroundColor">
        <p>{{title}}</p>
    </div>

    <div class="countries-wrapper">
        <empty-state *ngIf="countries.length === 0"></empty-state>
        <div 
            class="country" 
            *ngFor="let country of countries; let i = index"
            [style.border-left-color]="countriesBorderColors[i]">
                <div class="country-data">
                    <p class="country-name">{{country.countryName}}</p>
                    <p *ngIf="country.tfrIndex && country.tfrIndex > 0">{{textConstants.TFR + country.tfrIndex + '%'}}</p>
                    <div class="ivf-cycles-add-potential">
                        <p>{{MATH.round(country.currentCycles / 1000) + 'k' + textConstants.IVF_CYCLES}}</p>
                        <p>|</p>
                        <p>{{textConstants.ADD_POTENTIAL + MATH.round(country.additionalCyclePotential / 1000) + 'k'}}</p>
                    </div>
                    <div class="ivf-clinics">
                        <p *ngIf="country.numberOfIvfClinics && country.numberOfIvfClinics > 0">{{country.numberOfIvfClinics + ' ' + textConstants.IVF_CLINICS}}</p>
                        <p *ngIf="country.privateIvfClinics && country.privateIvfClinics > 0">{{'(' + 'Private IVF Clinics:' + ' ' + country.privateIvfClinics + '%' + ')'}}</p>
                    </div>
                </div>
                
                <div class="pie-chart">
                    <canvas
                        baseChart
                        [type]="'pie'"
                        [data]="buildChartDataModel(country.additionalCyclePotentialPercentage, countriesBorderColors[i])"
                        >
                    </canvas>
                </div>
        </div>
    </div>

</div>
