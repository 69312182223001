<div class="component-wrapper">
    <fd-chip 
        [@fade] 
        (removeChip)="removeChip($event)" 
        *ngFor="let chip of chips" 
        [content]="chip.content" 
        [value]="chip.id"
        [backgroundColor]="backgroundColor">
    </fd-chip>
</div>
