import { Component } from '@angular/core';
import * as TextConstants from '../../constants/text.constants';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {
  textConstants = TextConstants;

}
