import * as textConstants from '../../../constants/text.constants';

export const REGION_MAP_ICON_CONFIGURATION: any[] = [
    {
        regionName: textConstants.EUROPE,
        iconUrl: '../../../assets/images/europe_region.svg'
    },
    {
        regionName: textConstants.MEAR,
        iconUrl: '../../../assets/images/mear_region.svg'
    },
    {
        regionName: textConstants.APAC,
        iconUrl: '../../../assets/images/apac_region.svg'
    },
    {
        regionName: textConstants.LATAM,
        iconUrl: '../../../assets/images/latam_region.svg'
    },
    {
        regionName: textConstants.NA,
        iconUrl: '../../../assets/images/na_region.svg'
    },
    {
        regionName: textConstants.CN,
        iconUrl: '../../../assets/images/cn_region.svg'
    },
];