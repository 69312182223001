import { Injectable } from '@angular/core';
import * as RouteConstants from '../constants/route.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetGlobalCountriesDataResponseModel } from '../models/get-global-countries-data-response.model';
import { InitiativeDataModel } from '../models/initiatives-data.model';
import { FilterRequestModel } from '../models/filter-request.model';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private routeConstants = RouteConstants;

  constructor(private httpClient: HttpClient) { }

  getGlobalCountriesData(filterRequest: FilterRequestModel): Observable<GetGlobalCountriesDataResponseModel>{ 
    return this.httpClient.post<GetGlobalCountriesDataResponseModel>(this.routeConstants.GET_GLOBAL_COUNTRIES_DATA_ROUTE, filterRequest);
  }

  getInitiativeOptions(): Observable<InitiativeDataModel[]>{
    return this.httpClient.get<InitiativeDataModel[]>(this.routeConstants.GET_INITIATIVE_OPTIONS_ROUTE);
  }
}
