import { Component, Input, OnInit } from '@angular/core';
import * as TextConstants from '../../constants/text.constants';
import { MARKET_CP_COLORS_CONFIGURATION } from './configurations/markets-cp-colors-configuration';
import { CountryDataModel } from 'src/app/models/country-data.model';
import { ChartData } from 'chart.js';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'fd-markets-cp',
  templateUrl: './fd-markets-cp.component.html',
  styleUrls: ['./fd-markets-cp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FdMarketsCpComponent implements OnInit{
  @Input() headerBackgroundColor!: string;
  @Input() title!: string;
  @Input() countries: CountryDataModel[] = [];
  colors = MARKET_CP_COLORS_CONFIGURATION;
  MATH = Math;
  countriesBorderColors: string[] = [];
  textConstants = TextConstants;

  ngOnInit(): void {
    for (let i = 0; i < this.countries.length; i++) {
      this.countriesBorderColors.push(this.getRandomColor());
    }
  }

  buildChartDataModel(additionalCyclePotentialPercentage: number, color: string): ChartData<'pie'>{
    return {
      datasets: [
        {
          data: [additionalCyclePotentialPercentage, 100 - additionalCyclePotentialPercentage],
          backgroundColor: [color, 'white'],
          borderColor:[color]
        }
      ]
    }
  }

 private getRandomColor(): string{
    const randomIndex = Math.floor(Math.random() * this.colors.length);
    return this.colors[randomIndex];
  }
}
