<div class="component-wrapper">
    <p>{{textConstants.TIME_PERIOD}}</p>

    <div class="wrapper">
        <div class="inner-wrapper">
            <mat-date-range-input [rangePicker]="picker">
                <input 
                    matStartDate 
                    placeholder="Start date"
                    #dateRangeStart/>
                <input 
                    matEndDate 
                    placeholder="End date"
                    #dateRangeEnd
                    (dateChange)="emitRange(dateRangeStart, dateRangeEnd)"/>
            </mat-date-range-input>

            <svg 
                (click)="picker.opened = !picker.opened"
                class="arrow"
                [ngClass]="{'rotate': picker.opened}"
                matDatepickerToggleIcon 
                width="14" 
                height="8" 
                viewBox="0 0 14 8" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                    <path 
                        d="M1 1L7 7L13 1" 
                        stroke="#503291" 
                        stroke-linecap="round" 
                        stroke-linejoin="round"
                    />
            </svg> 
            <mat-date-range-picker #picker></mat-date-range-picker>
        </div>      
    </div>
</div>
