export const MARKET_CP_COLORS_CONFIGURATION: string[] = [
    '#FFC832',
    '#503291',
    '#2DBECD',
    '#0F69AF',
    '#149B5F',
    '#414450',
    '#96D7D2',
    '#B1AFBC',
    '#F26921',
    '#C74806',
    '#BD268F',
    '#A5CD50',
    '#9D96C9',
    '#EB3C96'
];