import { trigger, state, style, animate, transition } from '@angular/animations';

export const dropdownAnimation = trigger('toggle', [
    state('not-visible', style({height: '0', overflow: 'hidden', opacity: 0})),
    state('visible', style({height: '*', overflow: 'visible', opacity: 1})),
    transition('not-visible => visible', animate('400ms ease-in')),
    transition('visible => not-visible', animate('400ms ease-out')),
]);

export const addRemoveAnimation = trigger('fade', [
    state('in', style({opacity: 1})),
    state('out', style({opacity: 0})),
    transition(':enter', [
        style({opacity: 0}),
        animate('400ms ease-in')
    ]),
    transition(':leave', [
        animate('400ms ease-out', style({opacity: 0}))
    ])
]);