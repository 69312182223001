import { Component, Input } from '@angular/core';

@Component({
  selector: 'fd-flip-icon',
  templateUrl: './fd-flip-icon.component.html',
  styleUrls: ['./fd-flip-icon.component.scss']
})
export class FdFlipIconComponent {
  @Input() iconUrl!: string;
  @Input() rank!: number;
  @Input() backgroundColor!: string;
}
