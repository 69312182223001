<div class="wrapper" [ngClass]="{'shadow radius': isOptionContainerOpen}">
    <div class="input-arrow-wrapper">
        <input 
            type="text" 
            [formControl]="formControl"
            placeholder="Select country / countries"
            (keyup)="onKeyUp()"
        >

        <svg class="arrow" [ngClass]="{'rotate': isOptionContainerOpen}" (click)="onClick()" width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14.5" cy="14.5" r="14.5" transform="rotate(-180 14.5 14.5)" fill="#503291"/>
            <path d="M20.2119 13.1819L14.4998 18.4546L8.78767 13.1819" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> 
    </div>
    

    <div [@toggle]="state" class="options-wrapper shadow" >           
        <div class="option-wrapper" *ngFor="let suggestion of suggestions" (click)="emitChipContent(suggestion.id, suggestion.name)">  
            <mat-divider></mat-divider>         
            <p class="option"> {{suggestion.name}} </p> 
        </div>    
    </div> 

</div>  
