<div class="spinner" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>

<div class="page-wrapper">
  <div class="title-map-wrapper">
    <div class="title-wrapper">
      <p class="merck-font-title">{{textConstants.HOME_PAGE_TITLE}}</p>
    </div>
  
    <div class="map-info">
      <fd-map></fd-map>       

      <div class="info">
        <mat-icon fontIcon="info"></mat-icon>
        <p>{{textConstants.PICK_REGION}}</p>
      </div>
    </div>      
  </div>

  <fd-filter (filter)="getFilteredData($event)"></fd-filter>
  <div class="data-wrapper">
    <div class="is-cycles-countries">
      <fd-implementation-status 
        [subheading]="'Global view'"
        [data]="countriesData.implementationStatus"
        [femalePopulationWithInfertility]="countriesData.overallInfertilePopulationCount">
      </fd-implementation-status>

    <div class="cycles-countries">
      <fd-cycle-potential
        [currentCycles]="countriesData.cyclePotential.currentCycles"
        [potentialCycles]="countriesData.cyclePotential.potentialCycles">
      </fd-cycle-potential>  

      <div class="countries">

        <div class="top-countries">
          <div class="title">
            <p class="heading">{{textConstants.TOP_COUNTRIES}}</p>
            <p class="subheading">{{textConstants.TOP_COUNTRIES_SUBHEADING}}</p>
          </div>

          <div class="countries-wrapper">
            <empty-state *ngIf="countriesData.topCountries.length === 0"></empty-state>
            <div 
              class="country-wrapper" 
              *ngFor="let country of countriesData.topCountries; let i = index">
              <p [style.color]="countriesColors.get(i)" class="country">{{(i+1) + '. ' + country.name}}</p>
              <p [style.color]="countriesColors.get(i)" class="potential-amount">{{country.additionalCyclePotential + 'k'}}</p>
            </div>
          </div>
        </div> 
     </div> 
    </div>  

  </div>

  <div class="major-hurdles">
    <div class="title">
      <p class="heading">{{textConstants.MAJOR_HURDLES}}</p>
      <p class="subheading">{{textConstants.MH_ACROSS_COUNTRIES}}</p>
    </div>

    <empty-state  *ngIf="!firstTopHurdle || !secondTopHurdle"></empty-state>

    <div class="hurdles-wrapper" *ngIf="firstTopHurdle">
      <div class="hurdle-wrapper gap">
        <fd-flip-icon
          [iconUrl]="firstTopHurdle.iconUrl" 
          [rank]="1" 
          [backgroundColor]="firstTopHurdle.backgroundColor">
        </fd-flip-icon>
        <p [style.color]="firstTopHurdle.backgroundColor">
          <b>{{firstTopHurdle.name}}:</b>
          {{firstTopHurdle.description}}
        </p>
      </div>

      <div class="hurdle-wrapper" *ngIf="secondTopHurdle">
        <fd-flip-icon
          [iconUrl]="secondTopHurdle.iconUrl" 
          [rank]="2" 
          [backgroundColor]="secondTopHurdle.backgroundColor">
        </fd-flip-icon>
        
        <p [style.color]="secondTopHurdle.backgroundColor">
          <b>{{secondTopHurdle.name}}:</b>
          {{secondTopHurdle.description}}
        </p>
      </div>
    </div>
  </div>   

  <div class="table-wrapper">
    <empty-state  *ngIf="dataSource.length === 0"></empty-state>

    <mat-table [dataSource]="dataSource" *ngIf="dataSource.length > 0">
      <ng-container [matColumnDef]="'region'">
        <mat-header-cell *matHeaderCellDef class="transparent-no-border"> </mat-header-cell>
        <mat-cell 
          *matCellDef="let data" 
          class="region-info"> 
            {{data.regionName}} 
            <img [src]="data.iconUrl">
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'assesmentStatusCp'">
        <mat-header-cell *matHeaderCellDef  class="purple-text subheader"> {{textConstants.ASSESMENT_STATUS}}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="purple-text"> {{data.completedAssessmentStatuses + '/' + data.totalNumberOfCountries}} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'currentCycles'">
        <mat-header-cell *matHeaderCellDef  class="purple-text subheader"> {{textConstants.CURRENT_CYCLES}}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="purple-text"> {{data.currentCycles ? data.currentCycles : 'N/A'}} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'additionalCyclePotential'">
        <mat-header-cell *matHeaderCellDef  class="purple-text subheader"> {{textConstants.ADDITIONAL_CYCLE_POTENTIAL}}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="purple-text"> {{data.additionalCyclePotential ? data.additionalCyclePotential : 'N/A'}} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'topCountriesByPotential'">
        <mat-header-cell *matHeaderCellDef  class="purple-text subheader"> {{textConstants.TOP_COUNTRIES_BY_POTENTIAL}}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="purple-text"> {{ data.topCountries ? data.topCountries.join(' ') : 'N/A'}} </mat-cell>
      </ng-container>
  
      <ng-container [matColumnDef]="'assesmentStatusHurdles'">
        <mat-header-cell *matHeaderCellDef class="pink-text subheader"> {{textConstants.ASSESMENT_STATUS}}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="pink-text"> {{data.completedHurdleAssessmentStatuses + '/' + data.totalNumberOfCountries}} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'majorHurdles'">
        <mat-header-cell *matHeaderCellDef class="pink-text subheader"> {{textConstants.MAJOR_HURDLES}}</mat-header-cell>
        <mat-cell *matCellDef="let data"> 
          <div *ngFor="let hurdle of data.topHurdles" class="pink-text">
            <p>{{hurdle.name}}</p>
            <p>({{hurdle.points + '/' + data.completedAssessmentStatuses}})</p>
          </div>
        
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'initiativesPlanned'">
        <mat-header-cell *matHeaderCellDef class="blue-text subheader"> {{textConstants.INITIATIVES_PLANNED}}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="blue-text"> {{data.initiativesPlanned ? data.initiativesPlanned : 'N/A'}} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'initiativesLaunched'">
        <mat-header-cell *matHeaderCellDef class="blue-text subheader"> {{textConstants.INITIATIVES_LAUNCHED}}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="blue-text"> {{data.initiativesLaunched ? data.initiativesLaunched : 'N/A'}} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'initiativesOngoing'">
        <mat-header-cell *matHeaderCellDef class="blue-text subheader"> {{textConstants.INITIATIVES_ONGOING}}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="blue-text"> {{data.initiativesOngoing ? data.initiativesOngoing : 'N/A'}} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'initiativesCancelled'">
        <mat-header-cell *matHeaderCellDef class="blue-text subheader"> {{textConstants.INITIATIVES_CANCELLED}}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="blue-text"> {{data.initiativesCancelled ? data.initiativesCancelled : 'N/A'}} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'initiativesCompleted'">
        <mat-header-cell *matHeaderCellDef class="blue-text subheader"> {{textConstants.INITIATIVES_COMPLETED}}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="blue-text"> {{data.initiativesCompleted ? data.initiativesCompleted : 'N/A'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="header-row-region">
        <mat-header-cell class="transparent-no-border" *matHeaderCellDef [style.flex-basis]="'8.7%'"></mat-header-cell>
      </ng-container>  

      <ng-container matColumnDef="header-row-cp">
        <mat-header-cell class="cp-header" *matHeaderCellDef> {{textConstants.CYCLE_POTENTIAL}} </mat-header-cell>
      </ng-container>     

      <ng-container matColumnDef="header-row-hurdles">
        <mat-header-cell class="hurdles-header" *matHeaderCellDef> {{textConstants.HURDLES}} </mat-header-cell>
      </ng-container>

      <ng-container matColumnDef="header-row-initiatives">
        <mat-header-cell class="initiatives-header" *matHeaderCellDef> {{textConstants.INITIATIVES}} </mat-header-cell>
      </ng-container>
      
      <mat-header-row *matHeaderRowDef="headerRows"></mat-header-row>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>      
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
</div>



