import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { RegionPageComponent } from './pages/region-page/region-page.component';
import { AdminComponent } from './submodules/admin/pages/admin/admin.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { authGuard } from './guards/auth.guard';

const routes: Routes = [
  {path: '', component: HomePageComponent, canActivate: [authGuard]},
  {path: 'home', redirectTo: '/', pathMatch: 'full'},
  {path: 'region/:id', component: RegionPageComponent, canActivate: [authGuard]},
  {
    path: 'admin',
    component: AdminComponent,
    loadChildren: () =>
      import('./submodules/admin/admin.module').then((m) => m.AdminModule)
  },
  {path: 'access-denied', component: AccessDeniedComponent},
  {path: '**', component: ErrorPageComponent, canActivate: [authGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
