import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as TextConstants from '../../constants/text.constants';
import { FemalePopulationModel } from 'src/app/models/female-population.model';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'fd-bar-chart',
  templateUrl: './fd-bar-chart.component.html',
  styleUrls: ['./fd-bar-chart.component.scss']
})
export class FdBarChartComponent implements OnInit, OnChanges{
  @Input() data: FemalePopulationModel = {
    totalFemalePopulation: 0,
    womenAged25To39: 0,
    womenAged25To39WithInfertility: 0
  };
  dataLabels = ChartDataLabels;
  textConstants = TextConstants;
  
  public barChartData = {
    labels: [
      ['1', ' ', this.textConstants.FEMALE_POPULATION], 
      ['2', ' ', this.textConstants.WOMEN_AGED_25_39], 
      ['3', ' ', this.textConstants.WOMEN_25_39_INFERTILITY]
    ],
    datasets: [
      { 
        data: [
          this.data.totalFemalePopulation,
          this.data.womenAged25To39,
          Math.round(this.data.womenAged25To39WithInfertility / 1000000)
        ],
        borderRadius: {
          topLeft: 5,
          topRight: 5
        },
        backgroundColor: '#503291'
      }
    ],
  };

  public barChartOptions = {
    scales: {
      y: {
        grid: {
          display: false
        },
        display: false
      },
      x: {
        grid: {
          display: true,
          color: 'rgba(0, 0, 0, 0)',
          tickColor: '#503291'
        },
        ticks: {
          color: '#503291',
          font: {
            size: 10,
            weight: 'bold',
          }
        },
        border: {
          color: '#503291'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {    
        labels: { 
          value: {
            color: '#503291',
            font: {
              weight: 700,
              size: 20
            }      
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 50,
        right: 20,
        bottom: 20,
        left: 20,
      }
    }
  };

  ngOnInit(): void {
    Chart.defaults?.set('plugins.datalabels', {
      anchor: 'end',
      align: 'top'
    });

    this.barChartData.datasets[0].data = [
      this.data.totalFemalePopulation,
      this.data.womenAged25To39,
      Number((this.data.womenAged25To39WithInfertility / 1000000).toFixed(1))
    ];  
  }

  ngOnChanges(changes: SimpleChanges){
    const canvasEl = document.querySelector('canvas');
    const chartInstance = Chart.getChart(canvasEl!);
    const ctx = chartInstance?.ctx;
    chartInstance?.destroy();


    if(changes['data']){
      this.barChartData.datasets[0].data = [
        this.data.totalFemalePopulation,
        this.data.womenAged25To39,
        Number((this.data.womenAged25To39WithInfertility / 1000000).toFixed(1))
      ];

      //This is needed because the chart does not update when filters are applied.
      if(ctx){
        Chart.instances[0] = new Chart(ctx!, {
          type: 'bar',
          data:  this.barChartData,
          options: this.barChartOptions,
          plugins: [ChartDataLabels]
        })
      }     
    }
  }
}
