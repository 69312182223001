import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as TextConstants from '../../constants/text.constants';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';

@Component({
  selector: 'fd-calendar',
  templateUrl: './fd-calendar.component.html',
  styleUrls: ['./fd-calendar.component.scss']
})
export class FdCalendarComponent implements AfterViewInit { 
  textConstants = TextConstants;

  @Output() rangeSelect = new EventEmitter<{startDate: string | null | undefined, endDate: string | null | undefined}>;

  ngAfterViewInit(): void {
    this.setSeparatorWhenHidden();
  }

  emitRange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement){
    this.rangeSelect.emit({
      startDate: dateRangeStart.value,
      endDate: dateRangeEnd.value

    });
  }

  private setSeparatorWhenHidden(): void{
    let spanElement = document.querySelector(".mat-date-range-input-separator-hidden");
    spanElement!.textContent = '-';
  }
}
