<div class="cycle-potential">
    <div class="cp-inner-wrapper">
        <div class="cp-title-wrapper">
            <p class="heading">{{textConstants.COUNTRY_CYCLE_POTENTIAL}}</p>
            <p class="subheading">{{textConstants.COUNTRY_CYCLE_POTENTIAL_DESCRIPTION}}</p>
        </div>
        <div class="cp-data-wrapper">
            <div class="current-simulated-cycles cp-data">
                <p class="cycles-count">{{currentCycles + 'k'}}</p>
                <p class="cycles-description">{{textConstants.CURRENT_SIMULATED_CYCLES}}</p>
            </div>
            <div class="potential-additional-simulated-cycles cp-data">
                <p class="cycles-count">{{potentialCycles + 'k'}}</p>
                <p class="cycles-description">{{textConstants.POTENTIAL_ADDITIONAL_SIMULATED_CYCLES}}</p>
            </div>
        </div>
    </div>    
</div>
