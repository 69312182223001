import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import * as TextConstants from '../../constants/text.constants';
import { FdChipCollectionComponent } from '../fd-chip-collection/fd-chip-collection.component';
import { dropdownAnimation } from 'src/app/animations/custom.animations';
import { SELECT_CONFIG } from '../configurations/select-configuration';
import { HomeService } from 'src/app/services/home.service';
import { AddChipModel } from 'src/app/models/add-chip.model';
import { FilterRequestModel } from 'src/app/models/filter-request.model';

@Component({
  selector: 'fd-filter',
  templateUrl: './fd-filter.component.html',
  styleUrls: ['./fd-filter.component.scss'],
  animations: [ dropdownAnimation ]
})
export class FdFilterComponent implements OnInit{
  @ViewChildren(FdChipCollectionComponent) fdChipCollectionComponents!: QueryList<FdChipCollectionComponent>;
  @Output() filter = new EventEmitter<FilterRequestModel>;

  textConstants = TextConstants;
  SELECT_CONFIGURATION = SELECT_CONFIG;
  isFilterContainerOpen = false;
  state: string = 'not-visible';
  filterRequest: FilterRequestModel = {
    startDate: null,
    endDate: null,
    countries: [],
    hurdleOptions: [],
    statusOptions: [],
    functionOptions: [],
    initiativeOptions: []
  };

  constructor(private homeService: HomeService) {}

  ngOnInit(): void {
   this.homeService.getInitiativeOptions().subscribe(response => {
      this.SELECT_CONFIGURATION.find(x => x.type === 'Initiatives')!.options = response.map(x => {
       return {
        id: x.id,
        name: x.name
       }
      });

      this.SELECT_CONFIGURATION.find(x => x.type === 'Initiatives')!.options.unshift({
        id: 'All',
        name: 'All initiatives'
      })

    });
  }

  onClick(): void{
    this.isFilterContainerOpen = !this.isFilterContainerOpen;
    this.state = this.state === 'not-visible' ? 'visible' : 'not-visible';
  }

  callAddChipMethod(data: AddChipModel): void{
    const collection = this.fdChipCollectionComponents.find(x => x.type === data.collectionType);
    collection?.addChip(data);
  }   

  emitFilterRequestModel(){
    this.filterRequest.countries = [];
    this.filterRequest.functionOptions = [];
    this.filterRequest.hurdleOptions = [];
    this.filterRequest.initiativeOptions = [];
    this.filterRequest.statusOptions = [];
    
    this.fdChipCollectionComponents.forEach(c => {
      if(c.type?.toLowerCase() === 'hurdles'){
        this.filterRequest.hurdleOptions.push(...c.chips.map(x => x.id));
      }else if(c.type?.toLowerCase() === 'initiatives'){
        this.filterRequest.initiativeOptions.push(...c.chips.map(x => x.content));
      }else if(c.type?.toLowerCase() === 'statuses'){
        this.filterRequest.statusOptions.push(...c.chips.map(x => x.id));
      }else if(c.type?.toLowerCase() === 'functions'){
        this.filterRequest.functionOptions.push(...c.chips.map(x => x.id));
      }
    })

    this.filter.emit(this.filterRequest);
  }

  setDateRange(range: any){
    this.filterRequest.startDate = range.startDate;
    this.filterRequest.endDate = range.endDate;
  }
}

