import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

import { environment as env } from 'src/environments/environment';
import { RouterStateSnapshot } from '@angular/router';

import * as TextConstants from 'src/app/constants/text.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  textConstants = TextConstants;
  
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    const clonedRequest = request.clone({ 
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = this.textConstants.UNKNOWN_ERROR;
        
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          if (error.status === 401) {
            window.location.href = `${env.beBaseUrl}/api/auth/authorize?returnUrl=/`
          }
        }

        return throwError(() => errorMessage);
      }));
  }
}
