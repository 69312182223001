<div class="wrapper">
    <div class="chart-container">
        <canvas
            baseChart
            [data]="barChartData"
            [type]="'bar'"
            [options]="barChartOptions"
            [plugins]="[dataLabels]">
        </canvas>
    </div>
</div>


