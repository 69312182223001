<div class="container">
  <div class="header" *ngIf="!isInAdminPanel">
    <p class="merck-font" (click)="goToHomePage()">{{textConstants.MERCK}}</p>
    <p *ngIf="!showGreeting">{{textConstants.WELCOME + name}}</p>
  </div>
  
  <router-outlet></router-outlet>
  
  <div class="footer" *ngIf="!isInAdminPanel">
    <a href="https://www.merckgroup.com/en/privacy-statement.html" target="_blank">{{textConstants.DATA_PRIVACY}}</a>
  
    <div class="powered-by">
      <p>{{textConstants.POWERED_BY}}</p>
      <a href="https://www.we-lovedigi.com/" target="_blank"><img src="../assets/images/logo.svg" alt="Logo"/></a>   
    </div>
  </div>
</div>
