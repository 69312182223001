import { Component, ViewChild } from '@angular/core';
import * as TextConstants from '../../constants/text.constants';
import { FdChipCollectionComponent } from 'src/app/components/fd-chip-collection/fd-chip-collection.component';
import { addRemoveAnimation, dropdownAnimation } from 'src/app/animations/custom.animations';
import { ActivatedRoute } from '@angular/router';
import { RegionService } from 'src/app/services/region.service';
import { RegionViewDataModel } from 'src/app/models/region-view-data.model';
import { DISPLAYED_COLUMNS_CONFIGURATION, HEADER_ROWS_CONFIGURATION } from './configurations/deep-dive-table-config';
import { TopHurdleViewModel } from 'src/app/models/top-hurdle-view.model';
import { HURDLES_CONFIGURATION } from '../home-page/configurations/hurdles-configuration';
import { AddChipModel } from 'src/app/models/add-chip.model';
import { REGION_MAP_ICON_CONFIGURATION } from './configurations/region-configuration';
import { CountryDataModel } from 'src/app/models/country-data.model';
import { FilterRequestModel } from 'src/app/models/filter-request.model';
import { SummarizedCountryDataModel } from 'src/app/models/summarized-country-data.model';
import html2canvas from 'html2canvas';
import { HurdleDataModel } from 'src/app/models/hurdle-data.model';
import { HurdlesPointsModel } from 'src/app/models/hurdles-points-model';

@Component({
  selector: 'app-region-page',
  templateUrl: './region-page.component.html',
  styleUrls: ['./region-page.component.scss'],
  animations: [ addRemoveAnimation, dropdownAnimation]
})
export class RegionPageComponent {
  @ViewChild(FdChipCollectionComponent) fdChipCollectionComponent!: FdChipCollectionComponent;

  deepDiveHeaderRows = HEADER_ROWS_CONFIGURATION;
  deepDivedisplayedColumns = DISPLAYED_COLUMNS_CONFIGURATION;
  hurdlesConfig = HURDLES_CONFIGURATION;
  regionMapIconsCongig = REGION_MAP_ICON_CONFIGURATION;

  textConstants = TextConstants;
  deepDiveDataSource: CountryDataModel[] = [];
  highPotentialCountries: CountryDataModel[] = [];
  mediumPotentialCountries: CountryDataModel[] = [];
  lowPotentialCountries: CountryDataModel[] = [];
  firstTopHurdle: TopHurdleViewModel | null = null;
  secondTopHurdle: TopHurdleViewModel | null = null;
  regionId!: number;
  currentYear = new Date().getFullYear();
  isTableToggled = false;
  state = 'not-visible';
  showSpinner = false;

  summarizedData!: SummarizedCountryDataModel;
  hurdlesPoints: HurdlesPointsModel = {
    awareness: 0,
    financial: 0,
    patientCentricity: 0,
    referral: 0
  };

  filterRequest: FilterRequestModel = {
    startDate: null,
    endDate: null,
    countries: [],
    statusOptions: [],
    functionOptions: [],
    hurdleOptions: [],
    initiativeOptions: []
  };

  regionData: RegionViewDataModel = {
    regionName: '',
    iconUrl: '',
    countries: [],
    cyclePotential: {
      potentialCycles: 0,
      currentCycles: 0
    },
    femalePopulation: {
      totalFemalePopulation: null,
      womenAged25To39: null,
      womenAged25To39WithInfertility: 0
    },
    implementationStatus: {
      cyclePotential: 0,
      marketHurdles: 0,
      initiativesCancelled: 0,
      initiativesCompleted: 0,
      initiativesLaunched: 0,
      initiativesOngoing: 0,
      initiativesPlanned: 0
    },
    topHurdles: [],
    countriesData: []
  };
  
  constructor(private route: ActivatedRoute,
    private regionService: RegionService) {
    this.regionId = Number(this.route.snapshot.paramMap.get('id'));  
    this.getFilteredData(this.filterRequest); 
  }

  addChipToCollection(chipData: AddChipModel): void{
    this.fdChipCollectionComponent.addChip(chipData);
  }
  
  downloadPdf(): void {
    const element = document.body;

    html2canvas(element).then(canvas => {
      // Convert the canvas to an image (base64 encoded PNG)
      const image = canvas.toDataURL('image/png');
  
      // Download or display the image
      const link = document.createElement('a');
      link.href = image;
      link.download = `${this.regionData.regionName}.png`;
      link.click();
    });
  }

  downloadCsv(): void {
    var csv = this.generateCsvFile();
    var csvFile;
    var downloadLink;
    var filename = `${this.regionData.regionName} report`;

    // CSV file
    csvFile = new Blob([csv], {type: "text/csv"});

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = "none";

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
  }

  toggleDeepDiveTable(): void{
    this.isTableToggled = !this.isTableToggled;
    this.state = this.state === 'not-visible' ? 'visible' : 'not-visible';
  }

  getFilteredData(request: FilterRequestModel){
    this.showSpinner = true;

    const countryOptions = this.fdChipCollectionComponent?.chips.map(x => x.id) ?? [];
    request.countries = countryOptions;

    this.regionService.getRegionData(this.regionId, request).subscribe(response => {
      this.regionData = response;

      this.deepDiveDataSource = this.regionData.countriesData;

      this.highPotentialCountries = this.regionData.countriesData.filter(x => x.additionalCyclePotentialPercentage >= 70);
      this.mediumPotentialCountries = this.regionData.countriesData.filter(x => x.additionalCyclePotentialPercentage >= 50 && x.additionalCyclePotentialPercentage < 70);
      this.lowPotentialCountries = this.regionData.countriesData.filter(x => x.additionalCyclePotentialPercentage < 50);

      this.regionData.regionName = this.regionData.regionName.toUpperCase();
      const iconUrl = this.regionMapIconsCongig.find(x => x.regionName?.toLowerCase() === this.regionData.regionName?.toLowerCase())?.iconUrl ?? null;
      this.regionData.iconUrl = iconUrl;

      this.firstTopHurdle = null;
      this.secondTopHurdle = null;

      if(this.regionData.topHurdles.length >= 1){
        this.firstTopHurdle = this.hurdlesConfig.find(x => x.name === this.regionData.topHurdles[0])!;
      }
      if(this.regionData.topHurdles.length >= 2){
        this.secondTopHurdle = this.hurdlesConfig.find(x => x.name === this.regionData.topHurdles[1])!;
      }

      const totalCurrentPotential = this.regionData.countriesData.map(cd => cd.currentPotential).reduce((acc, value) => acc + value, 0);
      const totalAdditionalCyclePotential = this.regionData.countriesData.map(cd => cd.additionalCyclePotential).reduce((acc, value) => acc + value, 0);

      this.summarizedData = {
        totalCurrentPotential: totalCurrentPotential,
        totalCurrentCycles: this.regionData.countriesData.map(cd => cd.currentCycles).reduce((acc, value) => acc + value, 0),
        totalAdditionalCyclePotential: totalAdditionalCyclePotential,
        totalAdditionalCyclePotentialPercentage: Number(((totalAdditionalCyclePotential / totalCurrentPotential) * 100).toFixed(2)),
        totalNumberOfIvfClinics: this.regionData.countriesData.map(cd => cd.numberOfIvfClinics).reduce((acc, value) => (acc ?? 0) + (value ?? 0), 0),
        totalCancelledInitiatives: this.regionData.countriesData.map(cd => cd.initiativesCancelled).reduce((acc, value) => acc + value.length, 0),
        totalCompletedInitiatives: this.regionData.countriesData.map(cd => cd.initiativesCompleted).reduce((acc, value) => acc + value.length, 0),
        totalLaunchedInitiatives: this.regionData.countriesData.map(cd => cd.initiativesLaunched).reduce((acc, value) => acc + value.length, 0),
        totalOngoingInitiatives: this.regionData.countriesData.map(cd => cd.initiativesOngoing).reduce((acc, value) => acc + value.length, 0),
        totalPlannedInitiatives: this.regionData.countriesData.map(cd => cd.initiativesPlanned).reduce((acc, value) => acc + value.length, 0)
      };

      this.regionData.countriesData.forEach(x => {
        if(x.hurdleData.awareness != null || 
          x.hurdleData.financial != null ||
          x.hurdleData.patientCentricity != null || 
          x.hurdleData.referral != null){           
          const maxKey = this.keyOfMaxValue(x.hurdleData);
          this.hurdlesPoints[maxKey as keyof HurdlesPointsModel]++;
        }        
      });

      this.showSpinner = false;
    });
  }

  filterByCountry(){
    this.filterRequest.startDate = null;
    this.filterRequest.endDate = null;
    this.filterRequest.functionOptions = [];
    this.filterRequest.hurdleOptions = [];
    this.filterRequest.initiativeOptions = [];
    this.filterRequest.statusOptions = [];

    this.getFilteredData(this.filterRequest);
  }

  private generateCsvFile(){
    var csv = [];

    csv.push('Cycle Potential, Market Hurdles, Initiatives Planned, Initiatives Launched, Initiatives Ongoing, Initiatives Cancelled, Initiatives Completed');    
    csv.push(Object.values(this.regionData.implementationStatus).join(','));
    csv.push('~~~~');

    csv.push('Current total stimulated cycles (k), Potential additional stimulated cycles (k)');
    csv.push(Object.values(this.regionData.cyclePotential).join(','));
    csv.push('~~~~');

    csv.push('Major Hurdles');
    csv.push(Object.values(this.regionData.topHurdles).join(' '));
    csv.push('~~~~');

    csv.push('Total female population (million), Woman aged 25-39 (million), Woman aged 25-39 with infertility (million)');
    csv.push(Object.values(this.regionData.femalePopulation ?? {}).join(','));
    csv.push('~~~~');

    csv.push('HP MARKETS >70%');
    csv.push('Country, TFR (%), IVF cycles, Additional potential, IVF clinics (k), Private IVF clinics (%)');
    var highPotentialCountriesData: any[] = [];
    this.highPotentialCountries.forEach(x => {
      var row: any[] = [];
      row.push(x.countryName);
      row.push(x.tfrIndex);
      row.push(x.currentCycles);
      row.push(x.additionalCyclePotential);
      row.push(x.numberOfIvfClinics);
      row.push(x.privateIvfClinics);

      highPotentialCountriesData.push(row.join(','));
    })
    csv.push(highPotentialCountriesData.join('\n'));
    csv.push('~~~~');

    csv.push('MP MARKETS 50-70%');
    csv.push('Country, TFR (%), IVF cycles, Additional potential, IVF clinics (k), Private IVF clinics (%)');
    var mediumPotentialCountriesData: any[] = [];
    this.mediumPotentialCountries.forEach(x => {
      var row: any[] = [];
      row.push(x.countryName);
      row.push(x.tfrIndex);
      row.push(x.currentCycles);
      row.push(x.additionalCyclePotential);
      row.push(x.numberOfIvfClinics);
      row.push(x.privateIvfClinics);

      mediumPotentialCountriesData.push(row.join(','));
    })
    csv.push(mediumPotentialCountriesData.join('\n'));
    csv.push('~~~~');

    csv.push('LP MARKETS');
    csv.push('Country, TFR (%), IVF cycles, Additional potential, IVF clinics (k), Private IVF clinics (%)');
    var lowPotentialCountriesData: any[] = [];
    this.lowPotentialCountries.forEach(x => {
      var row: any[] = [];
      row.push(x.countryName);
      row.push(x.tfrIndex);
      row.push(x.currentCycles);
      row.push(x.additionalCyclePotential);
      row.push(x.numberOfIvfClinics);
      row.push(x.privateIvfClinics);

      lowPotentialCountriesData.push(row.join(','));
    })
    csv.push(lowPotentialCountriesData.join('\n'));
    csv.push('~~~~');

    csv.push(`Deep Dive ${this.regionData.regionName}`);
    csv.push('Country, Assessment status, Current Potential, Current Cycles, Additional Cycle Potential, Additional Cycle Potential (%), Number of IVF Clinics, Assessment status - Hurdles, Awareness (%), Referral (%), Financial (%), Patient Centricity (%), Initiatives Planned, Initiatives Launched, Initiatives Ongoing, Initiatives Cancelled, Initiatives Completed');
    var countriesData: any[] = [];
    this.regionData.countriesData.forEach(x => {
      var row: any[] = [];
      row.push(x.countryName);
      row.push(x.assessmentStatus);
      row.push(x.currentPotential);
      row.push(x.currentCycles);
      row.push(x.additionalCyclePotential);
      row.push(x.additionalCyclePotentialPercentage);
      row.push(x.numberOfIvfClinics);
      row.push(x.hurdleData.assessmentStatus);
      row.push(x.hurdleData.awareness);
      row.push(x.hurdleData.referral);
      row.push(x.hurdleData.financial);
      row.push(x.hurdleData.patientCentricity);
      row.push(x.initiativesPlanned.join(' '));
      row.push(x.initiativesLaunched.join(' '));
      row.push(x.initiativesOngoing.join(' '));
      row.push(x.initiativesCancelled.join(' '));
      row.push(x.initiativesCompleted.join(' '));

      countriesData.push(row.join(','));
    })
    csv.push(countriesData.join('\n'));

    return csv.join('\n');
  }

  private keyOfMaxValue(model: HurdleDataModel): keyof HurdleDataModel {
    return Object.keys(model)
      .reduce((a: string, b: string) => ((model[a as keyof HurdleDataModel] ?? 0) > (model[b as keyof HurdleDataModel] ?? 0) ? a : b)) as keyof HurdleDataModel;
  }
}
