<div class="spinner" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>

<div id="page-wrapper" class="page-wrapper">
    <div class="header">
        <div class="title-wrapper">
            <p class="title">{{textConstants.DEEP_DIVE}}</p>
        </div>
    </div>
    <div class="data-wrapper">
        <div class="filter-wrapper">
            <div class="region-name-search-field-wrapper">
                <div class="region">
                    <img [src]="regionData.iconUrl" alt="Region Icon">
                    <p class="region-name">{{regionData.regionName}} region</p>
                </div>

                <fd-search-field 
                    (valueSelect)="addChipToCollection($event)"
                    [options]="regionData.countries">
                </fd-search-field>
            </div>

            <div class="chips-wrapper">
                <fd-chip-collection [backgroundColor]="'white'">
                </fd-chip-collection>
                <div 
                    [@fade] 
                    class="filter-button-wrapper" 
                    *ngIf="this.fdChipCollectionComponent?.chips!.length > 0 ?? false;"
                    >
                        <button 
                            mat-flat-button
                            (click)="filterByCountry()">
                            {{textConstants.FILTER_BUTTON_TEXT}}
                        </button>
                </div>
            </div>

            <fd-filter 
                class="kpi-filter"
                (filter)="getFilteredData($event)">
            </fd-filter>
        </div>
        <div class="data">
            <fd-implementation-status 
                class="implementation-status"
                [subheading]="textConstants.COUNTRY_VIEW"
                [data]="regionData.implementationStatus"
                [femalePopulationWithInfertility]="regionData.femalePopulation.womenAged25To39WithInfertility">
            </fd-implementation-status>

            <div class="cycle-potential-major-hurdles-wrapper">
                <fd-cycle-potential
                    [currentCycles]="regionData.cyclePotential.currentCycles"
                    [potentialCycles]="regionData.cyclePotential.potentialCycles">
                </fd-cycle-potential>
                
                <div class="major-hurdles">
                    <div class="title-wrapper">
                        <p class="heading">{{textConstants.MAJOR_HURDLES}}</p>
                        <p class="subheading">{{textConstants.MH_ACROSS_COUNTRIES}}</p>
                    </div>
                    <div class="top-hurdles-wrapper">
                        <empty-state *ngIf="!firstTopHurdle && !secondTopHurdle"></empty-state>
                        
                        <div class="hurdle" *ngIf="firstTopHurdle">
                            <fd-flip-icon
                                [iconUrl]="firstTopHurdle.iconUrl" 
                                [rank]="1" 
                                [backgroundColor]="firstTopHurdle.backgroundColor">
                            </fd-flip-icon>

                            <p class="hurdle-text" [style.color]="firstTopHurdle.backgroundColor">
                                <b>{{firstTopHurdle.name}}:</b>
                                {{firstTopHurdle.description}}
                            </p>                               
                        </div>

                        <div class="hurdle" *ngIf="secondTopHurdle">
                            <fd-flip-icon
                                [iconUrl]="secondTopHurdle.iconUrl"
                                [rank]="2" 
                                [backgroundColor]="secondTopHurdle.backgroundColor">
                            </fd-flip-icon>

                            <p class="hurdle-text" [style.color]="secondTopHurdle.backgroundColor">
                                <b>{{secondTopHurdle.name}}:</b>
                                {{secondTopHurdle.description}}
                            </p>                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bar-chart-reports-wrapper">
            <div class="bar-chart">
                <div class="title-wrapper">
                    <p class="heading">{{textConstants.TOTAL_FEMALE_POPULATION}}</p>
                    <p class="subheading">{{textConstants.FEMALE_POPULATION}}</p>
                </div>

                <empty-state
                    *ngIf="!regionData.femalePopulation?.totalFemalePopulation &&
                    !regionData.femalePopulation?.womenAged25To39 &&
                    !regionData.femalePopulation?.womenAged25To39WithInfertility">
                </empty-state>

               <fd-bar-chart
                    *ngIf="regionData.femalePopulation?.totalFemalePopulation ||
                    regionData.femalePopulation?.womenAged25To39 || 
                    regionData.femalePopulation?.womenAged25To39WithInfertility"
                    [data]="regionData.femalePopulation">
                </fd-bar-chart>
            </div>

            <div class="reports-wrapper">
                <div class="pdf-report">
                    <p class="report-title">{{textConstants.GENERATE_PDF}}</p>
                    <img src="../../../assets/images/generate_pdf_icon.png">
                    <button
                         mat-flat-button
                         (click)="downloadPdf()">
                            {{textConstants.DOWNLOAD_PDF}}
                        </button>
                </div>

                <div class="csv-report">
                    <p class="report-title">{{textConstants.GENERATE_CSV}}</p>
                    <img src="../../../assets/images/generate_csv_icon.png">
                    <button 
                        mat-flat-button
                        (click)="downloadCsv()">
                            {{textConstants.DOWNLOAD_CSV}}
                        </button>
                </div>
            </div>
        </div>

        <div class="fd-markets-cp-wrapper">
            <div class="side-title">
                <p>{{currentYear + ' ' + textConstants.CYCLE_POTENTIAL + ' IN ' + regionData.regionName}}</p>
            </div>
            <empty-state
                *ngIf="highPotentialCountries.length === 0 &&
                mediumPotentialCountries.length === 0 &&
                lowPotentialCountries.length === 0">
            </empty-state>

            <div 
                class="fd-markets-cp-inner-wrapper"
                *ngIf="highPotentialCountries.length > 0 || 
                mediumPotentialCountries.length > 0 ||
                lowPotentialCountries.length > 0">
                <fd-markets-cp
                    [title]="textConstants.HP_MARKETS"
                    [headerBackgroundColor]="'#503291'"
                    [countries]="highPotentialCountries">
                </fd-markets-cp>
                <fd-markets-cp
                    [title]="textConstants.MP_MARKETS"
                    [headerBackgroundColor]="'#EB3C96'"
                    [countries]="mediumPotentialCountries">
                </fd-markets-cp>
                <fd-markets-cp
                    [title]="textConstants.LP_MARKETS"
                    [headerBackgroundColor]="'#0F69AF'"
                    [countries]="lowPotentialCountries">
                </fd-markets-cp>
            </div>
            
        </div>

        <div class="deep-dive-in-region-table-wrapper">
            <div class="title-wrapper" [ngStyle]="state == 'not-visible' ? {'margin-bottom': '0'} : {}">
                <div class="inner-wrapper">
                    <p>{{textConstants.DEEP_DIVE_REGION + regionData.regionName}}</p>
                    <p *ngIf="state == 'not-visible'" class="info">{{textConstants.LEARN_ABOUT_MARKET_POTENTIAL}}</p>
                </div>
                
                <svg 
                    class="arrow" 
                    [ngClass]="{'rotate': isTableToggled}" 
                    (click)="toggleDeepDiveTable()" 
                    width="29" 
                    height="29" 
                    viewBox="0 0 29 29" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg">
                        <circle 
                            cx="14.5" 
                            cy="14.5" 
                            r="14.5" 
                            transform="rotate(-180 14.5 14.5)" 
                            fill="#503291"
                        />
                        <path 
                            d="M20.2119 13.1819L14.4998 18.4546L8.78767 13.1819" 
                            stroke="white" 
                            stroke-width="2" 
                            stroke-linecap="round" 
                            stroke-linejoin="round"
                        />
                </svg> 

            </div>
            <empty-state [@toggle]="state" *ngIf="deepDiveDataSource.length === 0"></empty-state>

            <mat-table [@toggle]="state" [dataSource]="deepDiveDataSource" *ngIf="deepDiveDataSource.length > 0">
                <ng-container [matColumnDef]="'country'">
                    <mat-header-cell *matHeaderCellDef class="transparent-no-border"></mat-header-cell>
                    <mat-cell *matCellDef="let data" class="subheader"> {{data.countryName}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="subheader"> Total </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'assesmentStatus'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.ASSESMENT_STATUS}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{data.assessmentStatus}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef>N/A</mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'currentPotential'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.CURRENT_POTENTIAL}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.currentPotential ? (data.currentPotential | number: '1.0-0': 'fr') : 'N/A'}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{summarizedData.totalCurrentPotential | number: '1.0-0': 'fr' }} </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'currentCycles'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.CURRENT_CYCLES}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.currentCycles ? (data.currentCycles | number: '1.0-0': 'fr') : 'N/A'}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{summarizedData.totalCurrentCycles | number: '1.0-0': 'fr'}} </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'additionalCyclePotential'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.ADDITIONAL_CYCLE_POTENTIAL}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.additionalCyclePotential ? (data.additionalCyclePotential | number: '1.0-0': 'fr') : 'N/A'}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{summarizedData.totalAdditionalCyclePotential | number: '1.0-0': 'fr'}} </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'additionalCyclePotentialInPercentage'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.ADDITIONAL_CYCLE_POTENTIAL_PERCENTAGE}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{data.additionalCyclePotentialPercentage ? data.additionalCyclePotentialPercentage : 'N/A'}}% </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{summarizedData.totalAdditionalCyclePotentialPercentage}}% </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'numberOfIvfClinics'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.NUMBER_OF_IVF_CLINICS}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{data.numberOfIvfClinics ? (data.numberOfIvfClinics | number: '1.0-0': 'fr') : 'N/A'}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{summarizedData.totalNumberOfIvfClinics | number: '1.0-0': 'fr'}} </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'assessmentStatusHurdles'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.ASSESMENT_STATUS}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.hurdleData.assessmentStatus ?? 'N/A'}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef>N/A</mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'awarenessHurdles'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.AWARENESS}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{data.hurdleData.awareness ? data.hurdleData.awareness + '%' : 'N/A'}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{hurdlesPoints.awareness + '/' + regionData.countries.length}} </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'refferalHurdles'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.REFFERAL}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{data.hurdleData.referral ? data.hurdleData.referral + '%' : 'N/A'}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{hurdlesPoints.referral + '/' + regionData.countries.length}}</mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'financialHurdles'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.FINANCIAL}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{data.hurdleData.financial ? data.hurdleData.financial +  '%' : 'N/A'}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef>  {{hurdlesPoints.financial + '/' + regionData.countries.length}}</mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'patientCentricityHurdles'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.PATIENT_CENTRICITY}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{data.hurdleData.patientCentricity ? data.hurdleData.patientCentricity + '%' : 'N/A'}} </mat-cell>
                    <mat-footer-cell *matFooterCellDef>  {{hurdlesPoints.patientCentricity + '/' + regionData.countries.length}}</mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'initiativesPlanned'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.INITIATIVES_PLANNED}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> 
                        <div class="initiatives-wrapper">
                            <p *ngIf="data.initiativesPlanned.length === 0">N/A</p>
                            <p *ngFor="let initiative of data.initiativesPlanned; let i = index">
                                {{i+1 + '.' + initiative}} 
                            </p> 
                        </div>                                            
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{summarizedData.totalPlannedInitiatives}} </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'initiativesLaunched'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.INITIATIVES_LAUNCHED}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> 
                        <div class="initiatives-wrapper">
                            <p *ngIf="data.initiativesLaunched.length === 0">N/A</p>
                            <p *ngFor="let initiative of data.initiativesLaunched; let i = index">
                                {{i+1 + '.' + initiative}} 
                            </p> 
                        </div> 
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{summarizedData.totalLaunchedInitiatives}} </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'initiativesOngoing'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.INITIATIVES_ONGOING}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> 
                        <div class="initiatives-wrapper">
                            <p *ngIf="data.initiativesOngoing.length === 0">N/A</p>
                            <p *ngFor="let initiative of data.initiativesOngoing; let i = index">
                                {{i+1 + '.' + initiative}} 
                            </p> 
                        </div> 
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{summarizedData.totalOngoingInitiatives}} </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'initiativesCancelled'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.INITIATIVES_CANCELLED}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> 
                        <div class="initiatives-wrapper">
                            <p *ngIf="data.initiativesCancelled.length === 0">N/A</p>
                            <p *ngFor="let initiative of data.initiativesCancelled; let i = index">
                                {{i+1 + '.' + initiative}} 
                            </p> 
                        </div> 
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{summarizedData.totalCancelledInitiatives}} </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'initiativesCompleted'">
                    <mat-header-cell *matHeaderCellDef class="subheader">{{textConstants.INITIATIVES_COMPLETED}}</mat-header-cell>
                    <mat-cell *matCellDef="let data"> 
                        <div class="initiatives-wrapper">
                            <p *ngIf="data.initiativesCompleted.length === 0">N/A</p>
                            <p *ngFor="let initiative of data.initiativesCompleted; let i = index">
                                {{i+1 + '.' + initiative}} 
                            </p> 
                        </div> 
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef> {{summarizedData.totalCompletedInitiatives}} </mat-footer-cell>
                </ng-container>

                <ng-container [matColumnDef]="'header-row-country'">
                    <mat-header-cell *matHeaderCellDef class="transparent-no-border" [style.flex-basis]="'5%'"></mat-header-cell>
                </ng-container>

                <ng-container [matColumnDef]="'cyclePotential'">
                    <mat-header-cell *matHeaderCellDef class="cp-header">{{textConstants.CYCLE_POTENTIAL}}</mat-header-cell>
                </ng-container>

                <ng-container [matColumnDef]="'hurdles'">
                    <mat-header-cell *matHeaderCellDef class="hurdles-header">{{textConstants.HURDLES}}</mat-header-cell>
                </ng-container>

                <ng-container [matColumnDef]="'initiatives'">
                    <mat-header-cell *matHeaderCellDef class="initiatives-header">{{textConstants.INITIATIVES}}</mat-header-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="deepDiveHeaderRows"></mat-header-row>
                <mat-header-row *matHeaderRowDef="deepDivedisplayedColumns"></mat-header-row>      
                <mat-row *matRowDef="let row; columns: deepDivedisplayedColumns;"></mat-row>
                <mat-footer-row *matFooterRowDef="deepDivedisplayedColumns"></mat-footer-row>

            </mat-table>

        </div>
        
    </div>
</div>
    
    