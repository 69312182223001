export const HOME_PAGE_TITLE = 'FERTILITY MARKET EXPANSION DASHBOARD';
export const HOME_PAGE_SUB_TITLE = 'Welcome! Lorem Ipsum dolor sit amet..';
export const MERCK = 'meRck';
export const WELCOME = 'Welcome, ';
export const DATA_PRIVACY = 'Data Privacy';
export const POWERED_BY = 'Powered by';

export const DEEP_DIVE = "Deep Dive";
export const REGION_PAGE_SUBTITLE = "Lorem Ipsum dolor sit amet consequter ...";
export const IMPLEMENTATION_STATUS = "Implementation Status";
export const COUNTRY_VIEW = "Country View";
export const COUNTRY_CYCLE_POTENTIAL = "Country Cycle Potential";
export const COUNTRY_CYCLE_POTENTIAL_DESCRIPTION = "# Current cycles vs.  #theoretical additional cycles globally for countries in scope";
export const CURRENT_SIMULATED_CYCLES = "Current total # stimulated cycles in all countries in scope";
export const POTENTIAL_ADDITIONAL_SIMULATED_CYCLES = "Potential additional # stimulated cycles in all countries in scope";
export const MAJOR_HURDLES = "Major Hurdles";
export const MH_ACROSS_COUNTRIES ="Major hurdles across Top countries";

export const TIME_PERIOD = 'Time Period';
export const PICK_REGION = 'Pick the relevant region';
export const FILTER_KPI_DATA = 'Filter KPI Data';
export const SEARCH = "Search";

export const OPEN = 'Open';
export const INFO_INPUT = "Information Input";
export const INFO_INPUT_DESCRIPTION = "Input relevant information";
export const USER_MANAGEMENT = "User Management";
export const USER_MANAGEMENT_DESCRIPTION = "Manage users";
export const SYSTEM_NOTIFICATION = "System Notification";
export const SYSTEM_NOTIFICATION_DESCRIPTION = "Manage system notifications";

export const FIRST_NAME = "First Name";
export const LAST_NAME = "Last Name";
export const NAME = "Name";
export const EMAIL = "Email";
export const COUNTRY = "Country";
export const CITY = "City";
export const RIGHTS = "Rights";
export const SAVE = "Save";
export const TYPE = "Type...";
export const SELECT = "Select";

export const CREATE_NEW = "Create New";
export const PLEASE_INPUT_INFO = "Please input the necessary information";

export const PICK_COUNTRY = "Pick country";
export const CYCLE_POTENTIAL_NORMAL_CASING = "Cycle Potential";
export const HURDLES_NORMAL_CASING = "Hurdles";
export const INITIATIVES_NORMAL_CASING = "Initiatives";

export const FILTER_BUTTON_TEXT = "Filter";
export const TOP_COUNTRIES = 'Top countries';
export const TOP_COUNTRIES_SUBHEADING = 'Top countries by # of potential additional cycles';

export const HURDLES = 'HURDLES';
export const CYCLE_POTENTIAL = 'CYCLE POTENTIAL';
export const INITIATIVES = 'INITIATIVES';
export const ASSESMENT_STATUS = 'Assessment Status';
export const CURRENT_CYCLES = 'Current Cycles';
export const ADDITIONAL_CYCLE_POTENTIAL = 'Additional Cycle Potential';
export const ADDITIONAL_CYCLE_POTENTIAL_PERCENTAGE = 'Additional Cycle Potential';
export const TOP_COUNTRIES_BY_POTENTIAL = 'Top Countries by Potential';
export const NUMBER_OF_IVF_CLINICS = 'Number of IVF Clinics';
export const PRIVATE_IVF_CLINICS = 'Private IVF Clinics (%)';
export const INITIATIVES_PLANNED = 'Planned';
export const INITIATIVES_LAUNCHED = 'Launched';
export const INITIATIVES_ONGOING = 'Ongoing';
export const INITIATIVES_CANCELLED = 'Cancelled';
export const INITIATIVES_COMPLETED = 'Completed';
export const AWARENESS = 'Awareness';
export const REFFERAL = 'Referral';
export const FINANCIAL = 'Financial';
export const PATIENT_CENTRICITY = 'Patient Centricity';
export const TFR_INDEX = "TFR Index (%)";
export const CURRENT_POTENTIAL = "Cycle Potential";
export const TOTAL = "Total";

export const EUROPE = 'Europe';
export const MEAR = 'Mear';
export const APAC = 'Apac';
export const LATAM = 'Latam';
export const NA = 'North America';
export const CN = 'China';

export const GENERATE_PDF = 'Generate Report';
export const GENERATE_CSV = 'Generate CSV';
export const DOWNLOAD_PDF = "Download Report";
export const DOWNLOAD_CSV = "Download CSV";

export const TOTAL_FEMALE_POPULATION = 'Total female population in the Region';
export const FEMALE_POPULATION = 'Female Population (in Million)';

export const FEMALE_POPULATION_LABEL = 'Female population';
export const WOMEN_AGED_25_39 = 'Women aged 25-39';
export const WOMEN_25_39_INFERTILITY = 'Women aged 25-39 with infertility';

export const TFR = 'TFR ';
export const IVF_CYCLES = ' IVF cycles';
export const ADD_POTENTIAL = 'Add potential ';
export const IVF_CLINICS = ' IVF clinics';
export const HP_MARKETS = 'HP MARKETS, >70%';
export const MP_MARKETS = 'MP MARKETS, 50-70%';
export const LP_MARKETS = 'LP MARKETS';
export const DEEP_DIVE_REGION = 'Deep Dive ';
export const LEARN_ABOUT_MARKET_POTENTIAL = 'Learn about market potential in detail';

export const CHOOSE_STATUS = 'Choose status';

export const ERROR_MESSAGE = 'Oops! Looks like the page you’re trying to access doesn’t exist.';
export const ERROR_404 = 'error 404!';
export const GO_BACK = 'Go back';
export const NO_DATA_TO_SHOW = 'No data to show'
export const UNKNOWN_ERROR = 'An unknown error occurred!';

export const SNACK_BAR_MESSAGE = 'Your changes have been saved';
export const REQUIRED_FIELDS_MESSAGE = 'Required fields are marked with an asterisk (*)';

export const USER_ADDED_SUCCESS = 'User added successfully';
export const USER_ADDED_FAILURE = 'Adding user failed';
export const ACCESS_DENIED = 'ACCESS DENIED';
export const ACCESS_DENIED_SUBTITLE = 'Please contact your administrator to grant you access';

export const ARE_YOU_SURE_DELETE_USER = 'Are you sure you want to delete this user?';
export const CLICKING_BELOW_DELETES_USER = 'Upon clicking the delete button will permanently delete the user.';
export const CANCEL = 'Cancel';
export const DELETE = 'Delete';
export const USER_DELETED = 'User has been deleted';
export const USER_DELETE_FAILED = 'Deleting the user failed';