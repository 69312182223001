export const TABLE_DISPLAYED_COLUMNS_CONFIGURATION: string[] = [
    'region',
    'assesmentStatusCp',
    'currentCycles',
    'additionalCyclePotential',
    'topCountriesByPotential',
    'assesmentStatusHurdles',
    'majorHurdles',
    'initiativesPlanned',
    'initiativesLaunched',
    'initiativesOngoing',
    'initiativesCancelled',
    'initiativesCompleted'
];