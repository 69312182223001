import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'fd-admin-panel-breadcrumb',
  templateUrl: './fd-admin-panel-breadcrumb.component.html',
  styleUrls: ['./fd-admin-panel-breadcrumb.component.scss']
})
export class FdAdminPanelBreadcrumbComponent implements OnInit {
  breadcrumbs: {label: string, url: string}[] = [];

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.adminBreadcrumbs$.subscribe(x => this.breadcrumbs = x);
  }
}
