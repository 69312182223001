export const MY_DATE_FORMAT = {
    parse: {
      dateInput: 'YYYY/MM/DD', 
    },
    display: {
      dateInput: 'YYYY/MM/DD',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    }
  };