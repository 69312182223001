<div class="component-wrapper">
    <div class="heading-wrapper">
        <p class="heading">{{textConstants.IMPLEMENTATION_STATUS}}</p>
        <p class="subheading">{{subheading}}</p>
    </div>

    <div class="fd-card-wrapper">
        <fd-is-card color="#F26921" boxShadow="1px 1px 40px 5px rgba(242, 105, 33, 0.50)" text="Women With Infertility (25-39y)" [value]="femalePopulationWithInfertility | number: '1.0-0': 'fr'" width="56%"></fd-is-card>
        <fd-is-card color="#EB3C96" boxShadow="1px 1px 40px 5px rgba(235, 60, 150, 0.50)" text="Cycle Potential" [value]="data.cyclePotential | number: '1.0-0': 'fr'" width="49.65%"></fd-is-card>
        <fd-is-card color="#503291" boxShadow="1px 1px 30px 5px rgba(80, 50, 145, 0.50)" text="Market Hurdles" [value]="data.marketHurdles" width="43.32%"></fd-is-card>
        <fd-is-card color="#0F69AF" boxShadow="1px 1px 30px 5px rgba(15, 105, 175, 0.50)" text="Initiatives Planned" [value]="data.initiativesPlanned" width="36.99%"></fd-is-card>
        <fd-is-card color="#2DBECD" boxShadow="1px 1px 30px 5px rgba(45, 190, 205, 0.50)" text="Initiatives Launched" [value]="data.initiativesLaunched" width="30.66%"></fd-is-card>
        <fd-is-card color="#96D7D2" boxShadow="1px 1px 30px 5px rgba(150, 215, 210, 0.50)" text="Initiatives Ongoing" [value]="data.initiativesOngoing" width="24.33%"></fd-is-card>
        <fd-is-card color="#A5CD50" boxShadow="1px 1px 30px rgba(165, 205, 80, 0.50)" text="Initiatives Cancelled" [value]="data.initiativesCancelled" width="18%"></fd-is-card>
        <fd-is-card color="#149B5F" boxShadow="1px 1px 30px rgba(20, 155, 95, 0.50)" text="Initiatives Completed" [value]="data.initiativesCompleted" width="14%"></fd-is-card>
    </div>
</div>
