export const ROUTE_ICONS_CONFIG: RouteItemIcon[] = [
    {
        route: "home",
        iconUrl: "../../../../../assets/images/admin_home_nav_icon.svg",
        activeIconUrl: "../../../../../assets/images/admin_home_nav_icon_active.svg",
        active: false,
        hovered: false
    },
    {
        route: "user-management",
        iconUrl: "../../../../../assets/images/admin_user_management_nav_icon.svg",
        activeIconUrl: "../../../../../assets/images/admin_user_management_nav_icon_active.svg",
        active: false,
        hovered: false
    },
    {
        route: "information-input",
        iconUrl: "../../../../../assets/images/admin_info_input_nav_icon.svg",
        activeIconUrl: "../../../../../assets/images/admin_info_input_nav_icon_active.svg",
        active: false,
        hovered: false
    }
];

export interface RouteItemIcon {
    route: string,
    iconUrl: string,
    activeIconUrl: string,
    active: boolean,
    hovered: boolean
}