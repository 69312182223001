export const DISPLAYED_COLUMNS_CONFIGURATION: string[] = [
    'country',
    'assesmentStatus',
    'currentPotential',
    'currentCycles',
    'additionalCyclePotential',
    'additionalCyclePotentialInPercentage',
    'numberOfIvfClinics',
    'assessmentStatusHurdles',
    'awarenessHurdles',
    'refferalHurdles',
    'financialHurdles',
    'patientCentricityHurdles',
    'initiativesPlanned',
    'initiativesLaunched',
    'initiativesOngoing',
    'initiativesCancelled',
    'initiativesCompleted'
];

export const HEADER_ROWS_CONFIGURATION: string[] = [
    'header-row-country',
    'cyclePotential',
    'hurdles',
    'initiatives'
];